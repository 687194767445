

@media (max-width: 840px) {
  .messenger-main{
      margin-top: 60px;  
  }
}


@media (max-width: 816px) {
  .messenger-main{
      margin-top: 90px;  
  }
}
@media (max-width: 768px) {
    .messenger-main{
        margin-top: 100px;  
    }
  }

  @media (max-width: 668px) {
    .messenger-main{
        margin-top: 90px;  
    }
  }
  @media (max-width: 568px) {
    .messenger-main{
        margin-top: 90px;  
    }
  }
  @media (max-width: 474px) {
    .messenger-main{
        margin-top: 150px;  
    }
  }
  @media (max-width: 468px) {
    .messenger-main{
        margin-top: 160px;  
    }
  }
  @media (max-width: 450px) {
    .messenger-main{
        margin-top: 170px;  
    }
  }
  @media (max-width: 438px) {
    .messenger-main{
        margin-top: 180px;  
    }
  }
  @media (max-width: 410px) {
    .messenger-main{
        margin-top: 195px;  
    }
  }
  @media (max-width: 392px) {
    .messenger-main{
        margin-top: 255px;  
    }
  }
  @media (max-width: 374px) {
    .messenger-main{
        margin-top: 290px;  
    }
  }
  @media (max-width: 342px) {
    .messenger-main{
        margin-top: 310px;  
    }
  }
