/* styles.css */
.main-flex-container {
    height: "83vh",
}


@media (max-width: 1350px) {
    .main-buttons {
      margin-top: 0px;
      margin-bottom: 5px;
      height: 100px;
    }
  }
@media (max-width: 1230px) {
    .main-buttons {
      margin-top: 0px;
      margin-bottom: 5px;
    }
  }
@media (max-width: 1180px) {
    .main-buttons {
      margin-top: 0px;
      margin-bottom: 40px;
      
    }
  }
  @media (max-width: 790px) {
    .main-buttons {
      margin-top: 0px;
      margin-bottom: 78px;
    }
  }
  @media (max-width: 769px) {
    .main-buttons {
      margin-bottom: 0px;
      height: 50px;
    }
  }
  @media (max-width: 440px) {
    .main-buttons {
      margin-bottom: 0px;
      height: 80px;
    }
  }

  @media (max-width: 440px) {
    .sketch-design-button {
      margin-bottom: 0px;
      height: 40px;
    }
  .mobile-margin{
    margin-bottom: 270px;
  }
    .draw-design-text{
    margin-top: "40px";
  }
  }


  /* @media (max-width: 769px) {
    .main-buttons {
      margin-bottom: 0px;
      height: 50px;
    }
  }
  @media (max-width: 769px) {
    .main-buttons {
      margin-bottom: 0px;
      height: 50px;
    }
  } */

/* Media Query for Tablets */
@media (max-width: 1024px) {
    /* Adjust the column padding for tablets */
    .tablet-padding {
      padding: 10px;
    }
   
    /* Adjust the font size for tablets */
    .tablet-font-size {
      font-size: 30px;
    }
  }
