$customBackgroundColor: hsla(0,0%,39.2%,.25); // Default value
$strokeColor: #37cdb4; // Default value

:root {
  --custom-background-color: #{$customBackgroundColor};
  --stroke-color: #{$strokeColor};
}

.Dylan-Spencer{
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--stroke-color);
  color:transparent !important
}

.display.square {
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    background: var(--custom-background-color); // Use the CSS custom property
  }

  .square-bg{
    /* background-image: url('../src//Assets/Spencer.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; */
    background: var(--custom-background-color);
    /* border-radius: 8px; */
    /* padding-left: 10px; */
    /* padding-right: 10px; */
    /* border: 1px solid black; */
  }
  .woodColor-texture-bg{
    background-color: var(--custom-background-color);;
    background-image: url("../src/Assets/Texture.png");
    background-repeat: repeat-x;
    background-position: center;
    background-size: contain;
    /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  }
  .woodColor-bg{
    background-color: var(--custom-background-color);;
    // background-image: url("https://www.transparenttextures.com/patterns/wood-pattern.png");
    /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  }

  
  // .skeleton-bg{
  //   border: 2px solid var(--custom-background-color);
  //   border-radius: 10px;
  //   background: repeating-linear-gradient(90deg,var(--custom-background-color),var(--custom-background-color) 2px,hsla(0,0%,39.2%,0) 0,hsla(0,0%,39.2%,0) 85px);
  // }
  .skeleton-bg{
    border: 2px solid hsla(0,0%,39.2%,.25);
    border-radius: 10px;
    background: repeating-linear-gradient(90deg,hsla(0,0%,39.2%,.25),hsla(0,0%,39.2%,.25) 2px,hsla(0,0%,39.2%,0) 0,hsla(0,0%,39.2%,0) 85px);
  }

  /* Box Backing Starts*/

.display.box{
    z-index: -1;
    top: -20px;
      bottom: -10px;
      left: -30px;
      right: -17px;
      transform: none;
      background: var(--custom-background-color);
  }
  
  .display.box .bottom,  .display.box .left,  .display.box .right,  .display.box .top {
    position: absolute;
  }
  
  .display.box.top {
  top: 0;
  }
  
  .display.box .bottom {
    bottom: -16px;
  }
  
  .display.box .bottom, .display.box .top {
    width: calc(100% + 16px);
    margin: 0 -16px 0 0;
    height: 16px;
  }
  
  .display.box .bottom:before, .display.box .top:before {
    content: "";
    display: block;
    border-color: hsla(0,0%,39.2%,.4) hsla(0,0%,39.2%,.4) transparent transparent;
    border-style: solid;
    border-width: 8px;
    height: 0;
    width: 0;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .display.box .bottom span, .display.box .top span {
    display: block;
    height: 100%;
    margin: 0 16px;
    background: hsla(0,0%,39.2%,.4);
  }
  
  .display.box .bottom:after, .display.box .top:after {
    content: "";
    display: block;
    border-color: transparent transparent hsla(0,0%,39.2%,.4) hsla(0,0%,39.2%,.4);
    border-style: solid;
    border-width: 8px;
    height: 0;
    width: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .display.box .left, .display.box .right {
    height: calc(100% + 14px);
    margin: 0 0 -16px;
    width: 16px;
  }
  
  .display.box .right {
    right: -16px;
  }
  
  .display.box .left {
    margin-top: 2px;
  }
  
  .display.box .left:before, .display.box .right:before {
    content: "";
    display: block;
    border-color: transparent transparent hsla(0,0%,39.2%,.45) hsla(0,0%,39.2%,.45);
    border-style: solid;
    border-width: 8px;
    height: 0;
    width: 0;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .display.box .left span, .display.box .right span {
    display: block;
    height: calc(100% - 32px);
    margin: 16px 0;
    background: hsla(0,0%,39.2%,.45);
  }
  
  .display.box .left:after,  .display.box .right:after {
    content: "";
    display: block;
    border-color: hsla(0,0%,39.2%,.45) hsla(0,0%,39.2%,.45) transparent transparent;
    border-style: solid;
    border-width: 8px;
    height: 0;
    width: 0;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  
  /* Box Backing Ends*/