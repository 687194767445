  /* Media query for desktop screens */
  .continue-mobile {
    width: 20%;
    font-size: 16px;
    margin-right: 10px;
    padding: 10px;
    font-weight: 600; /* Note: corrected the property name to font-weight */
    background-color: #AFE9E0;
    color: #03705E; /* Text color */
    border-radius: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 440px) {
    .continue-mobile {
        width: 40%; /* Show the desktop div */
        
        
    }
}