@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Beatrice";
}

@font-face {
  font-family: "Beatrice-Display";
  font-weight: bolder;
  font-style: italic;
  src: url("./Fonts/Beatrice-Display.ttf") format("truetype");
}
@font-face {
  font-family: "Beatrice";
  src: url("./Fonts/Beatrice.OTF");
}

/* Font Files*/

@font-face {
  font-family: "Wylie";
  src: url("./Fonts/yma.woff");
}
@font-face {
  font-family: "Avery";
  src: url("./Fonts/tamoro-script-black.woff");
}
@font-face {
  font-family: "Hunter";
  src: url("./Fonts/mina-noodle.woff");
}
@font-face {
  font-family: "Izzy";
  src: url("./Fonts/handsome.woff");
}
@font-face {
  font-family: "Kyle";
  src: url("./Fonts/corp-sans-round-condensed.woff");
}
@font-face {
  font-family: "Logan";
  src: url("./Fonts/signerica-fat.woff");
}
@font-face {
  font-family: "Parker";
  src: url("./Fonts/showcase-script.woff");
}
@font-face {
  font-family: "Dylan";
  src: url("./Fonts/din-bold-alt.woff");
}
@font-face {
  font-family: "Spencer";
  src: url("./Fonts/mistral-webfont.woff");
}
@font-face {
  font-family: "Goldie";
  src: url("./Fonts/bayshore.woff");
}
@font-face {
  font-family: "Carter";
  src: url("./Fonts/ThisSerif.woff");
}
@font-face {
  font-family: "Cleo";
  src: url("./Fonts/Femen.woff");
}
@font-face {
  font-family: "Charlie";
  src: url("./Fonts/gulim.woff");
}
@font-face {
  font-family: "James";
  src: url("./Fonts/ThisSans.woff");
}
@font-face {
  font-family: "Quinn";
  src: url("./Fonts/CervoNeue.woff");
}
@font-face {
  font-family: "Micah";
  src: url("./Fonts/SilverSouth.woff");
}
@font-face {
  font-family: "Lola";
  src: url("./Fonts/Selfie.woff");
}
