
@media (max-width: 768px) {
    .pickup-map{
        margin-top: 80px;  
    }
  }

  @media (max-width: 672px) {
    .pickup-map{
        margin-top: 100px;  
    }
  }
  @media (max-width: 530px) {
    .pickup-map{
        margin-top: 130px;  
    }
  }
  @media (max-width: 490px) {
    .pickup-map{
        margin-top: 180px;  
    }
  }
  @media (max-width: 482px) {
    .pickup-map{
        margin-top: 210px;  
    }
  }
  @media (max-width: 466px) {
    .pickup-map{
        margin-top: 250px;  
    }
  }
  @media (max-width: 392px) {
    .pickup-map{
        margin-top: 260px;  
    }
  }
 
  @media (max-width: 359px) {
    .pickup-map{
        margin-top: 300px;  
    }
  }
  @media (max-width: 354px) {
    .pickup-map{
        margin-top: 320px;  
    }
  }
  @media (max-width: 353px) {
    .pickup-map{
        margin-top: 350px;  
    }
  }
  @media (max-width: 347px) {
    .pickup-map{
        margin-top: 420px;  
    }
  }
  @media (max-width: 326px) {
    .pickup-map{
        margin-top: 450px;  
    }
  }
  @media (max-width: 292px) {
    .pickup-map{
        margin-top: 500px;  
    }
  }
