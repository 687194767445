

.enable-listing-style > ol {
  list-style: decimal;
  margin-bottom: 0px !important;
}
.enable-listing-style > p {
  margin-bottom: 0px !important;
}

.PhoneInputInput {
  border: none;
  outline: none;
}


.custom-bullets {
  list-style-type: none;
 
}
.offcanvas-backdrop {
  z-index: 6;
}
.navbar-parent {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient( 180deg, #ffffff 80.17%, rgba(113, 228, 209, 0) 100% );
  transition: background-color 0.3s linear, height 0.2s linear;
  border-bottom: unset;
  padding: 10px 0;
  z-index: 5;
}
.custom-bullets li::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px; 
  background-image: url("Assets/StarBullet.svg"); 
  background-size: cover;
  margin-right: 0.5em;
}
.ThankYouBG::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 20px; 
  background: linear-gradient(to right, #ff5733, #33ff57); 
  border-radius: 50% 50% 0 0;
  transform: translate(0, 100%);
  animation: wave 2s infinite alternate;
}

.hover-effect:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.selected {
  box-shadow: 0px 0px 10px 5px #d1a8ea;
  backdrop-filter: blur(0.5px);
}

.led.color:after {
  opacity: 0.15;
  background-color: #b3aeb0;
  box-shadow: 0 0 25px 25px #b3aeb0;
}

.led:after {
  content: "";
  display: block;
  position: absolute;
  top: 18px;
  bottom: 20px;
  left: 15px;
  right: 15px;
  background: #fff;
  box-shadow: 0 0 20px 20px #fff;
  z-index: 0;
  border-radius: 100px;
  opacity: 0.75;
  transition: opacity 0.6s ease-in-out;
  animation: glow-pulse 3s ease-in-out infinite;
}

.display {
  position: absolute;
  top: -20px;
  left: -20px;
  bottom: -20px;
  right: -20px;
  display: block;
}
.display.square {
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  background: hsla(0, 0%, 39.2%, 0.25);
}

.select-toggle {
  padding: 0px;
  position: relative;
  display: inline-block;
}

.toggle-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 24px;
  background-color: #f9f9f9 !important;
  border: none;
}
.toggle-input-user {
  background-color: #fff !important;
}

.toggle-icon {
  position: absolute;
  top: 40%;
  right: -10px;
  transform: translateY(-50%) rotate(-45deg);
  width: 12px;
  height: 12px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transition: transform 0.3s ease;
}
.toggle-icon-admin {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%) rotate(-45deg);
  width: 12px;
  height: 12px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transition: transform 0.3s ease;
}

.select-toggle select:focus + .toggle-icon-admin {
  transform: translateY(-50%) rotate(137deg);
}
.select-toggle select:focus + .toggle-icon {
  transform: translateY(-50%) rotate(137deg);
}

.vertical-button {
  position: fixed;
  margin-left: -90px;
  border-radius: 0px 0px 20px 20px;
  border: 2px solid #71e4d1;
  transform: rotate(270deg);
  top: 40%; 
  width: 220px; 
  height: 50px; 
  background-color: #d8f4f1; 
  color: #03705e; 
  outline: none; 
  cursor: pointer; 
  z-index: 9; 
}

.vertical-button:hover {
  box-shadow: 0px 5px 10px #d1a8ea;
}

.vertical-button-offcanvas {
  position: fixed;
  margin-left: 525px;
  border-radius: 0px 0px 20px 20px;
  border: 2px solid #71e4d1;
  transform: rotate(270deg);
  top: 40%;
  width: 220px; 
  height: 50px; 
  background-color: #d8f4f1; 
  color: #03705e;
  outline: none; 
  cursor: pointer; 
  z-index: 0; 
}

.vertical-button-offcanvas:hover {
  box-shadow: 0px 5px 10px #d1a8ea;
}

.chat-button {
  position: fixed;
  color: white;
  border-radius: 9999px;
  bottom: 1%;
  right: 2%;
  width: 10%;
  height: 60px;
  background-color: #bf6be3;
  cursor: pointer;
  margin-top: 40px; 
}

.chat-button:hover {
  box-shadow: 0px 5px 10px #d1a8ea;
}

.save-button {
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  margin-left: 180px;
  border-radius: 24px;
  border: 2px solid white;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 2px #ffff;
  text-transform: none;
  top: 18px; 
  color: #25ccb0; 
  cursor: pointer; 
  z-index: 999; 
}

.save-modal {
  background-color: #afe9e0;
  color: #03705e;
  text-transform: uppercase;
}
.save-modal:hover {
  background-color: white;
  color: #03705e;
  text-transform: uppercase;
}

.warning {
  background-color: rgba(
    217,
    255,
    158,
    0.7
  ); 
  backdrop-filter: blur(6.5px);
  box-shadow: 0px 0px 50px 10px #d9ff9e;

  overflow-x: hidden;
  overflow-y: auto;
 
}

.warning-title{
  font-size: 14px !important; 
  font-family: "Beatrice" !important;
  text-align: center !important;
  letter-spacing: 0.5px !important;
}

.warning-desc{
  font-size: 14px !important;
  font-family: "Beatrice" !important;
  letter-spacing: 0.5px !important;
  color: #BF6BE3 !important
}

.wood-bg {
  background-image: url("../src/Assets/Wood.png");
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
  
}

.transparent {
  background-color: transparent;
}

.dimmer {
  background-color: #8f8f8f;
  border-radius: 8px;
}

.shipping-bg {
  background-image: url("../src/Assets/Blue.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.rush-bg {
  background-image: url("../src/Assets/Rush.png");
  background-repeat: no-repeat;
  background-position: center;
 
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  margin-top: 20px;
  margin-bottom: 20px;
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #afe9e0;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #03705e;
}

.bgBlue {
  height: 300px;
  width: 100%;
  background-image: url("Assets/Blue.png");
  background-size: cover;
  background-position: center;
 
}

.enable-listing-style ul {
  list-style: unset;
  list-style-type: unset;
}

.border-radius {
  border-radius: 20px !important;
}

.custom-edit-modal-width .modal-dialog {
  max-width: 1500px !important;
}

.date-picker {
  border: 2px solid #afe9e0;
  outline: none;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
}

.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker-wrapper.admin-Dates {
  width: auto !important;
}

#country option[disabled]:first-child {
  color: #999999;
}

.ql-toolbar {
  border: none !important;
  border-bottom: 2px solid #afe9e0 !important;
}
.ql-container {
  border: none !important;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  background-color: #f9f9f9;
}
.input {
  background-color: #f9f9f9 !important;
}

/* Responsivness work */

/* Common styles for margin */
.margin-adjust {
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 1rem;
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .custom-bullets {
    font-size: 12px;
  }
  .custom-bullets ul {
    padding-left: 0px;
  }
  .margin-adjust {
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1rem;
  }
}
/* Media query for tablet and mobile views */
@media (max-width: 1024px) {
  .margin-adjust {
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1rem;
  }
} /* Media query for mobile view */
@media (max-width: 768px) {
  .super-margin-adjust {
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1rem;
  }
}
/* Media query for tablet and mobile views */
@media (max-width: 1024px) {
  .super-margin-adjust {
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1rem;
  }
}

@media (max-width: 900px) {
  .main-drawing-container {
    height: "87vh";
  }
}

/* Super custom uploaded files mobile view  */
/* Media Query for Mobile */
@media (max-width: 768px) {
  .main-drawing-container {
    height: "87vh";
  }

  /* Adjust the column padding for mobile */

  .mobile-padding {
    padding: 5px;
  }
  .tabs-margin {
    margin-top: 20px;
  }
  /* Adjust the font size for mobile */
  .mobile-font-size {
    font-size: 20px;
  }
  /* Adjust the column padding for mobile */
  .mobile-padding {
    padding: 5px;
  }
  /* Add margin between components in mobile view */
  .mobile-margin {
    margin-top: 20px;
  }
}

.continue-button-width-adjust {
  width: 20%;
  float: right; /* Add this line to make the button float right */
}

/* Media query for mobile view */
@media (max-width: 1000px) {
  .continue-button-width-adjust {
    width: 50%;
    float: right; /* Also float right on mobile view if needed */
  }
}

.ps-submenu-root{
  background-color: #AFE9E0 !important
}

.carousel-control-next-icon{
  background-color: #36CDB4 !important;
}

.carousel-control-prev-icon{
  background-color: #36CDB4 !important;
}
.carousel-indicators [data-bs-target]{
  background-color: #36CDB4 !important;
}

@media (min-width:1201px) and (max-width:1600px) {
  .sourceImage {
   height: 190px;
}
.sourceDiv{
  height: 190px;
}
.desktop-div{
overflow-y: auto;
overflow-x: hidden;
max-height:300px;
justify-content: flex-start !important;

}
}