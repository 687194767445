/* styles.css */
/* Media query for larger screen sizes */
/* Media query for larger screen sizes */


/* Media query for mobile view */
@media (max-width: 768px) {
    .flex-container {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      
    }
  }
