/* Media query for larger screen sizes */
/* Media query for larger screen sizes */

/* Media query for mobile view */
@media (max-width: 1280px), (max-width: 1480px) {
  .size-input {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .size-input {
    width: 115%;
  }
}
@media (max-width: 1100px) {
  .size-input {
    width: 115%;
  }
}
@media (max-width: 992px) {
  .size-input {
    width: 100%;
  }
}

/* Media query for smaller screenss */
@media (max-width: 991px) {
  
  .size-items {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .size-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .other-fonts {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .size-item {
    width: 55%;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .size-items {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}