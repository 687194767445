.custom-list {
  list-style: none;
  padding-left: 1em;
}

.custom-list li::before {
  content: "•";
  color: #8F8F8F;
  font-size: 14px;
  margin-right: 8px;
}
@media (max-width: 769px) {
    .card-group {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
