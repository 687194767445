/* Existing styling for larger screens */
.parent-container {
  /* Other styling properties for the parent container */
  position: relative;
}

.colors-list-container {
  position: relative;
  height: 70vh;
}

/* Add media query for mobile and tablet views */
@media (max-width: 768px), (max-width: 1080px) {
  .colors-list-container {
    display: flex;
    overflow: scroll;
    height: auto;
    padding-right:10px;
    justify-content: center;
    align-items: center;
    margin: 20px 0; /* Add margin for spacing */
  }
}
