@media (max-width: 440px) {
    .bottom-buttons {
      margin-bottom: 10px;
      height: 80px;
      background-color: "#afe9e0" ;
      height: 50px;
      width: 180px;
font-family:"Beatrice" ;
justify-content: space-between;
margin-top: 15px;
    }
  .bottom-example-buttons {
width: 180px;
color: #25CCB0;
font-size: 16px;
font-family: "Beatrice";
font-weight: 600;
text-transform: uppercase;
letter-spacing: 2px;
background-color: "#D8F4F1";
border-radius: 16px;
padding-right: 20px;
padding-left: 20px;
}
}