.price-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .price-container {
    flex-direction: column; /* Change to column layout for mobile screens */
  }
}