
@media (max-width: 769px) {
    .header-tabs {
      margin-bottom: 0px;
      height: 50px;
    }
  }

 /* Media query for smaller screens */
 @media (max-width: 769px) {
  .acce-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }  
  .acce-container {
    width: 100%;
    display: flex; 
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}
  .other-fonts {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  } 

  .acce-item {
    width: 60%;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 440px) {
  .sketch-design-button {
    margin-bottom: 0px;
    height: 40px;
  }
.mobile-margin{
  margin-bottom: 270px;
}
  .preview-design-text{
  margin-top: "40px";
}
}