/* Media query for desktop screens */
/* .source-items {
    width: 100%;
    padding: "120px";
    
  }
  .source-item {
    width: 100%;
    padding: "20px";
  } */
/* Media query for smaller screens */
@media (min-width: 500px) and(max-width: 769px) {
  .source-items-backing {
    margin-bottom: 20px;
    /* width: 87%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .source-items {
    margin-bottom: 20px;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 500px) {
  .source-items-backing {
    margin-bottom: 20px;
    /* width: 87%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .source-items {
    margin-bottom: 20px;
    /* width: 87%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 769px) {


  .source-item {
    width: 70%;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
