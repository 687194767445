/* styles.css */
/* Media query for larger screen sizes */
/* Media query for larger screen sizes */



/* Media query for mobile view */
@media (max-width: 768px) {
    .shipping-details-items {
        margin-bottom: 50px;  
    }
}
/* Media query for mobile view */
@media (max-width: 630px) {
    .shipping-details-container {
        margin-top: 100px;  
    }
  }
/* Media query for mobile view */
@media (max-width: 600px) {
    .shipping-details-container {
        margin-top: 130px;  
    }
  }
/* Media query for mobile view */
@media (max-width: 430px) {
    .shipping-details-container {
        margin-top: 190px;  
    }
  }
/* Media query for mobile view */
@media (max-width: 410px) {
    .shipping-details-container {
        margin-top: 280px;  
    }
  }
  /* Media query for mobile view */
@media (max-width: 390px) {
    .shipping-details-container {
        margin-top: 330px;  
    }
  }
  /* Media query for mobile view */
@media (max-width: 370px) {
    .shipping-details-container {
        margin-top: 310px;  
    }
  }
  /* Media query for mobile view */
@media (max-width: 410px) {
    .shipping-details-container {
        margin-top: 280px;  
    }
  }