/* Default styles for columns */
.col-item {
    margin-top: 10px; /* Adjust margin for smaller screens */
  }
  /* Media query for tablet screens */
  @media (max-width: 768px) {
    .col-item {
      margin-top: 25px; /* Adjust margin for smaller screens */
    }
  }
 