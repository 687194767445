/* styles.css */
/* Media query for larger screen sizes */
/* Media query for larger screen sizes */



@media (max-width: 1000px)  {
    .drop-down {
 margin:auto;
 margin-bottom: 30px;
 width: 100%;
  }
}
@media (max-width: 700px)  {
    .drop-down {
 width: 100%;
  }
}
 /* Media query for smaller screens */
 @media (max-width: 769px) {
  .accessories-items {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }  
 
  .accessories-main {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
