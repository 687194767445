/* Default styling for both divs */
.mobile-tablet-div,
.desktop-div {
  display: none;
  padding: 20px;
  text-align: center;
}

/* Media query for mobile and tablet screens */
@media (max-width: 768px) {
  .mobile-tablet-div {
    display: block;
  }
}
/* Media query for mobile and tablet screens */
@media (max-width: 1080px) {
  .font-list {
    width: 100%;
    padding: 0px;
  }
}

/* Media query for desktop screens */
@media (min-width: 769px) {
  .desktop-div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  /* Adjust the order of the buttons to place them at the bottom */
  .desktop-div .button-style {
    order: 2; /* Move buttons to the end */
  }
}

/* Media query for smaller screens */
@media (max-width: 500px) {
  .source-item {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .other_fonts_container{
    margin-right: 0px !important;
  }
}

@media (min-width: 500px) and (max-width: 769px) {

  .other_fonts_container{
    margin-right: 0px !important;
  }
  .fonts-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .fonts-container p {
    width: 70%;
    font-size: 20px !important;
  }
  .other-fonts {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .desktop-div {
    display: none;
    padding: 20px;
    text-align: center;
  }

  .source-item {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 991px) {
  .mobile-display {
    display: none;
    margin: 0px;
  }
}
