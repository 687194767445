/* Existing styling for larger screens */
/* Default styling for both divs */

.parent-container {
    /* Other styling properties for the parent container */
    position: relative;
  }
  
  .backing-list-container {
    position: relative;
    flex-grow: 1;
    height: 65vh;
  }
  
  .mobile-button-container {
    align-items: start;
    margin: 0 0 0 7px;
  }
  
  /* New class for button styling */
  .button-style {
    width: 87%;
    height: 90px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-family: Beatrice;
    cursor: pointer;
    transition: background-color 0.3s ease, opacity 0.3s ease;
  }



  /* Add media query for mobile and tablet views */
  @media (max-width: 768px), (max-width: 991px) {
    .backing-list-container {
      display: flex;
      height: auto;
      flex-direction: column;
      overflow: auto;
      padding: 0;
      width: 100%;
    }

    .backing-color-list-container {
      /* Other styling properties for the parent container */
      margin-bottom: 15px;
    }
    .mobile-tablet-div {
        display: block; /* Show the mobile/tablet div */
    }
    .desktop-div {
        
         display: none; /* Show the desktop div */
      }
      .fonts-container {
        display: flex; 
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        /* margin: auto; */
   }
    .mobile-button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      /* display: flex; 
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 15px; */
    }
      /* New class for button styling */
  .button-style {
    width: 100%;
    height: 100px;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-family: Beatrice;
    margin: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease, opacity 0.3s ease;
  }
    /* Hide the larger screens button container */
    .backing-list-container > .m-3 {
      display: none;
      height: auto;
    }
  }
  /* Media query for smaller screens */
 @media (max-width: 769px) {
  .backing-items {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }  
  .backing-item {
    width: 65%;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
/* Define styles for desktop screens (adjust the breakpoint as needed) */
@media (min-width: 768px) {
  .button-container {
    flex-direction: column; /* Stack buttons vertically */
  }
}

/* Define styles for mobile screens (adjust the breakpoint as needed) */
@media (max-width: 767px) {
  .button-container {
    justify-content: center; /* Center align buttons horizontally */
  }

  .button-container button {
    margin: 0 10px; /* Add spacing between buttons */
  }
}