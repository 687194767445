/* Media query for mobile view */
@media (max-width: 768px) {
    .shipping-form{
        margin-Top: 60px;  
    }
  }
/* Media query for mobile view */
@media (max-width: 480px) {
    .shipping-form{
        margin-Top: 110px;  
    }
  }
@media (max-width: 380px) {
    .shipping-form{
        margin-Top: 190px;  
    }
  } 
@media (max-width: 340px) {
    .shipping-form{
        margin-Top: 250px;  
    }
  }