.shipping-detail{
   margin-bottom: 20px;
}
@media (max-width: 769px) {
    .shipping-detail {
 margin-bottom: 250px;
    }
  }

  /* // pickup */
  