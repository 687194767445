/* styles.css */
/* Media query for larger screen sizes */
/* Media query for larger screen sizes */



@media (max-width: 1000px)  {
    .delivery-tabs {
 margin-bottom: 20px;
margin-left: 35px;
width: 95%;
  }
}
 /* Media query for smaller screens */
 @media (max-width: 769px) {
  .delivery-items {
    margin-bottom: 20px;
    width: 87%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }  
  .delivery-item {
    width: 70%;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
